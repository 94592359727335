<template>
  <div class="page-edit">
    <container>
      <a-row :gutter="[0, 20]">
        <a-col :span="24">
          <router-link to="/" class="back-to-home">
            <a-icon type="home" class="mr-5"></a-icon>

            <span>Вернуться к списку объектов</span>
          </router-link>
        </a-col>

        <a-col :md="12" :xs="24">
          <a-collapse>
            <a-collapse-panel
              key="1"
              :header="`Культуры (всего: ${сulturesData.length})`"
            >
              <a-button class="mb-15" @click="createCulture">
                Добавить культуру
              </a-button>

              <a-table
                bordered
                :loading="сulturesLoading"
                :columns="columns"
                :data-source="сulturesData"
                :pagination="pagination"
              >
                <div
                  slot="operation"
                  slot-scope="text, record"
                  class="page-edit-operation-cell"
                >
                  <a-button
                    type="link"
                    icon="form"
                    @click="editCulture(record.key)"
                  ></a-button>

                  <a-popconfirm
                    title="Вы уверены что хотите удалить культуру?"
                    ok-text="Да"
                    cancel-text="Нет"
                    @confirm="deleteCulture(record.key)"
                  >
                    <a-button type="link" icon="delete"></a-button>
                  </a-popconfirm>
                </div>
              </a-table>
            </a-collapse-panel>

            <a-collapse-panel
              key="2"
              :header="`Направления (всего: ${directionsData.length})`"
            >
              <a-button class="mb-15" @click="createDirection">
                Добавить направление
              </a-button>

              <a-table
                bordered
                :loading="directionsLoading"
                :columns="columns"
                :data-source="directionsData"
                :pagination="pagination"
              >
                <div
                  slot="operation"
                  slot-scope="text, record"
                  class="page-edit-operation-cell"
                >
                  <a-button
                    type="link"
                    icon="form"
                    @click="editDirection(record.key)"
                  ></a-button>

                  <a-popconfirm
                    title="Вы уверены что хотите удалить направление?"
                    ok-text="Да"
                    cancel-text="Нет"
                    @confirm="deleteDirection(record.key)"
                  >
                    <a-button type="link" icon="delete"></a-button>
                  </a-popconfirm>
                </div>
              </a-table>
            </a-collapse-panel>
          </a-collapse>
        </a-col>
      </a-row>
    </container>

    <a-modal
      :title="modalTitle"
      :visible="editModalVisible"
      :confirm-loading="editConfirmLoading"
      ok-text="Сохранить"
      @ok="saveChanges"
      @cancel="onCloseEditModal"
    >
      <div class="ant-modal-body-content">
        <a-form-model>
          <a-form-model-item ref="name" label="Название *" prop="name">
            <a-input
              size="large"
              placeholder="Название"
              allow-clear
              v-model="editableFilter.name"
            ></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import apiRequest from '../js/helpers/apiRequest';

import Container from '../components/Container.vue';

const columns = [
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];

// const data = [
//   {
//     key: '1',
//     name: 'John Brown',
//   },
// ];

export default {
  name: 'EditFilters',

  components: {
    Container,
  },

  data() {
    return {
      columns,
      сulturesData: [],
      directionsData: [],
      pagination: false,
      сulturesLoading: false,
      directionsLoading: false,
      isEditCultures: false,
      isCreateCultures: false,
      isEditDirections: false,
      isCreateDirections: false,
      editConfirmLoading: false,
      editableFilter: {
        key: null,
        name: '',
      },
    };
  },

  computed: {
    editModalVisible() {
      const {
        isEditCultures,
        isCreateCultures,
        isEditDirections,
        isCreateDirections,
      } = this;

      return (
        isEditCultures ||
        isCreateCultures ||
        isEditDirections ||
        isCreateDirections
      );
    },

    modalTitle() {
      const {
        isEditCultures,
        isCreateCultures,
        isEditDirections,
        isCreateDirections,
      } = this;

      return isEditCultures
        ? 'Редактирование культуры'
        : isCreateCultures
        ? 'Создание культуры'
        : isEditDirections
        ? 'Редактирование направления'
        : isCreateDirections
        ? 'Создание направления'
        : '';
    },
  },

  created() {
    this.getCultures();
    this.getDirections();
  },

  methods: {
    onCloseEditModal() {
      this.isEditCultures = false;
      this.isCreateCultures = false;
      this.isEditDirections = false;
      this.isCreateDirections = false;

      this.editableFilter.key = null;
      this.editableFilter.name = '';
    },

    editCulture(key) {
      this.editableFilter.key = key;
      this.editableFilter.name = this.сulturesData.find(
        (сulture) => сulture.key === key
      ).name;
      this.isEditCultures = true;
    },

    editDirection(key) {
      this.editableFilter.key = key;
      this.editableFilter.name = this.directionsData.find(
        (direction) => direction.key === key
      ).name;
      this.isEditDirections = true;
    },

    createCulture() {
      this.isCreateCultures = true;
    },

    createDirection() {
      this.isCreateDirections = true;
    },

    async saveChanges() {
      try {
        const {
          isEditCultures,
          isCreateCultures,
          isEditDirections,
          isCreateDirections,
          editableFilter: { key, name },
        } = this;

        const body = new FormData();

        key ? body.append('id', key) : null;
        body.append('name', name);

        this.editConfirmLoading = true;

        if (isEditCultures) {
          const res = await apiRequest(
            '/admin/culture/update',
            'POST',
            body,
            true
          );

          if (res.culture) {
            this.сulturesData.find((сulture) => сulture.key === key).name =
              res.culture.name;
          }
        }

        if (isCreateCultures) {
          const res = await apiRequest(
            '/admin/culture/create',
            'POST',
            body,
            true
          );

          if (res.culture) {
            await this.getCultures();
          }
        }

        if (isEditDirections) {
          const res = await apiRequest(
            '/admin/direction/update',
            'POST',
            body,
            true
          );

          if (res.direction) {
            this.directionsData.find(
              (direction) => direction.key === key
            ).name = res.direction.name;
          }
        }

        if (isCreateDirections) {
          const res = await apiRequest(
            '/admin/direction/create',
            'POST',
            body,
            true
          );

          if (res.direction) {
            await this.getDirections();
          }
        }

        this.editConfirmLoading = false;
        this.onCloseEditModal();
      } catch (error) {
        console.log('saveChanges', error);
        this.editConfirmLoading = false;
      }
    },

    async deleteCulture(key) {
      try {
        const body = new FormData();

        body.append('id', key);

        const res = await apiRequest(
          '/admin/culture/delete',
          'POST',
          body,
          true
        );

        if (res.success) {
          this.сulturesData = this.сulturesData.filter(
            (сulture) => сulture.key !== key
          );
        }
      } catch (error) {
        console.log('deleteCulture', error);
      }
    },

    async deleteDirection(key) {
      try {
        const body = new FormData();

        body.append('id', key);

        const res = await apiRequest(
          '/admin/direction/delete',
          'POST',
          body,
          true
        );

        if (res.success) {
          this.directionsData = this.directionsData.filter(
            (direction) => direction.key !== key
          );
        }
      } catch (error) {
        console.log('deleteDirection', error);
      }
    },

    async getCultures() {
      try {
        this.сulturesLoading = true;
        const res = await apiRequest('/admin/cultures', 'GET', null, true);
        this.сulturesLoading = false;

        if (res.cultures) {
          this.сulturesData = res.cultures.map(({ id, name }) => ({
            key: id,
            name,
          }));
        }
      } catch (error) {
        console.log('getCultures', error);
        this.сulturesLoading = false;
        this.$notification.warning({
          message: 'Ошибка',
          description: 'Что-то пошло не так.. Не удалось загрузить культуры.',
        });
      }
    },

    async getDirections() {
      try {
        this.directionsLoading = true;
        const res = await apiRequest('/admin/directions', 'GET', null, true);
        this.directionsLoading = false;

        if (res.directions) {
          this.directionsData = res.directions.map(({ id, name }) => ({
            key: id,
            name,
          }));
        }
      } catch (error) {
        console.log('getDirections', error);
        this.directionsLoading = false;
        this.$notification.warning({
          message: 'Ошибка',
          description:
            'Что-то пошло не так.. Не удалось загрузить направления.',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.page-edit {
  padding-bottom: 25px;
}

.page-edit-operation-cell {
  display: flex;
  justify-content: center;

  .ant-btn {
    + .ant-btn {
      margin-left: 10px;
    }
  }
}
</style>
