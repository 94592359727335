<template>
  <container>
    <a-row :gutter="20">
      <a-col :md="12" :xs="24">
        <a-form-model
          class="page-profile-form"
          :model="form"
          @submit.prevent="onSubmit"
        >
          <a-form-model-item ref="name" label="Название объекта *" prop="name">
            <a-input
              size="large"
              placeholder="Название"
              v-model="form.name"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item ref="region" label="Область  *" prop="region">
            <a-select
              v-model="form.region"
              size="large"
              placeholder="Выберите область"
            >
              <a-select-option
                v-for="option in regions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item ref="district" label="Район  *" prop="district">
            <a-select
              v-model="form.district"
              size="large"
              placeholder="Выберите район"
            >
              <a-select-option
                v-for="option in districts"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            ref="address"
            label="Адрес объекта  *"
            prop="address"
          >
            <a-input
              size="large"
              placeholder="Адрес"
              v-model="form.address"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item ref="unp" label="УНП объеата" prop="unp">
            <a-input
              size="large"
              placeholder="УНП"
              v-model="form.unp"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item ref="managers" label="Менеджеры" prop="managers">
            <a-select
              v-model="form.managers"
              mode="multiple"
              size="large"
              placeholder="Выберите менеджеров"
            >
              <a-select-option
                v-for="option in managers"
                :key="option.id"
                :value="option.id"
              >
                {{ `${option.name} ${option.lastName}` }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            ref="directions"
            label="Направления работы"
            prop="directions"
          >
            <a-select
              v-model="form.directions"
              mode="multiple"
              size="large"
              placeholder="Выберите направления"
            >
              <a-select-option
                v-for="option in directions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-button
              type="primary"
              htmlType="submit"
              class="mr-10"
              :loading="confirmLoading"
            >
              Сохранить
            </a-button>

            <a-button @click="$router.back()"> Назад </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
    </a-row>
  </container>
</template>

<script>
import { mapState } from 'vuex';
import apiRequest from '../js/helpers/apiRequest.js';

import Container from '../components/Container.vue';

export default {
  name: 'CreateObject',

  components: {
    Container,
  },

  data() {
    return {
      confirmLoading: false,

      form: {
        name: '',
        region: undefined,
        district: undefined,
        address: '',
        unp: '',
        managers: [],
        directions: [],
      },
    };
  },

  computed: {
    ...mapState({
      object: (state) => state.objects.object,
      managers: (state) => state.managers.managers,
      regions: (state) => state.filters.regions,
      districts: (state) => state.filters.districts,
      directions: (state) => state.filters.directions,
    }),
  },

  methods: {
    async onSubmit() {
      try {
        const {
          form: { name, region, district, address, unp, managers, directions },
        } = this;
        const body = new FormData();

        body.append('name', name);
        body.append('address', address);
        body.append('region', region);
        body.append('district_id', district);
        body.append('unp', unp);

        managers.forEach((item) => {
          body.append('managers[]', item);
        });

        directions.forEach((item) => {
          body.append('directions[]', item);
        });

        this.confirmLoading = true;
        const res = await apiRequest('/object/add', 'POST', body, true);
        this.confirmLoading = false;

        if (res.object) {
          const { id } = res.object;
          this.$router.push(`/object/${id}`);
        }
      } catch (error) {
        console.log('handleSave:', error);
        this.confirmLoading = false;
        this.$notification.warning({
          message: 'Ошибка',
          description: 'Заполните все поля помеченные *',
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
