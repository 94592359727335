<template>
  <div class="filters">
    <a-form-model
      ref="filterForm"
      class="filters-form"
      :model="form"
      @submit.prevent="onSubmit"
    >
      <a-row class="filters-toggle-mobile">
        <a-col class="filters-col" :xs="{ span: 24 }">
          <a-button
            size="large"
            icon="filter"
            @click="visible = !visible"
          ></a-button>
        </a-col>
      </a-row>

      <a-row v-if="visible" class="filters-row" :gutter="15">
        <a-col
          class="filters-col"
          :lg="{ span: 4 }"
          :md="{ span: 8 }"
          :sm="{ span: 12 }"
          :xs="{ span: 24 }"
        >
          <a-form-model-item
            class="mb-0"
            label="По направлениям:"
            prop="direction"
          >
            <a-select
              v-model="form.directions"
              mode="multiple"
              size="large"
              placeholder="Выберите направление"
              @change="handleUpdateInfo"
            >
              <a-select-option
                v-for="option in options.directions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col
          class="filters-col"
          :lg="{ span: 4 }"
          :md="{ span: 8 }"
          :sm="{ span: 12 }"
          :xs="{ span: 24 }"
        >
          <a-form-model-item class="mb-0" label="По культурам:" prop="culture">
            <a-select
              v-model="form.cultures"
              mode="multiple"
              size="large"
              placeholder="Выберите культуру"
              @change="handleUpdateInfo"
            >
              <a-select-option
                v-for="option in options.cultures"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col
          class="filters-col"
          :lg="{ span: 4 }"
          :md="{ span: 8 }"
          :sm="{ span: 12 }"
          :xs="{ span: 24 }"
        >
          <a-form-model-item class="mb-0" label="По области:" prop="region">
            <a-select
              v-model="form.regions"
              mode="multiple"
              size="large"
              placeholder="Выберите область"
              @change="handleUpdateInfo"
            >
              <a-select-option
                v-for="option in options.regions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col
          class="filters-col"
          :lg="{ span: 4 }"
          :md="{ span: 8 }"
          :sm="{ span: 12 }"
          :xs="{ span: 24 }"
        >
          <a-form-model-item class="mb-0" label="По району:" prop="region">
            <a-select
              v-model="form.districts"
              mode="multiple"
              size="large"
              placeholder="Выберите область"
              @change="handleUpdateInfo"
            >
              <a-select-option
                v-for="option in options.districts"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col
          class="filters-col"
          :lg="{ span: 4 }"
          :md="{ span: 8 }"
          :sm="{ span: 12 }"
          :xs="{ span: 24 }"
        >
          <a-form-model-item class="mb-0" label="По менеджеру:" prop="manager">
            <a-select
              v-model="form.managers"
              mode="multiple"
              size="large"
              placeholder="Выберите менеджера"
              @change="handleUpdateInfo"
            >
              <a-select-option
                v-for="option in options.managers"
                :key="option.id"
                :value="option.id"
              >
                {{ `${option.name} ${option.lastName}` }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col
          class="filters-col"
          :lg="{ span: 4 }"
          :md="{ span: 8 }"
          :sm="{ span: 12 }"
          :xs="{ span: 24 }"
        >
          <a-form-model-item class="mb-0" label="По статусу:" prop="status">
            <a-select
              v-model="form.statuses"
              mode="multiple"
              size="large"
              placeholder="Выберите статус"
              @change="handleUpdateInfo"
            >
              <a-select-option
                v-for="option in options.statuses"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col
          class="filters-col mt-20"
          :sm="userRole === 'admin' ? 12 : 24"
          :xs="24"
        >
          <a-form-model-item class="mb-0" prop="search">
            <a-input
              size="large"
              placeholder="Поиск (название, адрес, унп)"
              v-model="search"
              allowClear
              @input="handleSearch"
            ></a-input>
          </a-form-model-item>
        </a-col>

        <a-col
          v-if="userRole === 'admin'"
          class="filters-col mt-20"
          :sm="12"
          :xs="24"
        >
          <a-form-model-item class="mb-0" prop="noDate">
            <div class="no-data-switch">
              <a-switch v-model="noData" @change="handleUpdateInfo"></a-switch>

              <span>Не вся информация</span>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapMutations, mapActions } from 'vuex';
import apiRequest from '../js/helpers/apiRequest.js';
import parseObject from '../js/helpers/parseObject.js';

export default {
  name: 'Filters',

  props: {
    options: {
      type: Object,
      required: true,
    },

    searchName: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      visible: false,
      form: {
        directions: [],
        cultures: [],
        regions: [],
        districts: [],
        managers: [],
        statuses: [],
      },
      search: '',
      noData: false,
    };
  },

  watch: {
    searchName(val) {
      this.search = val;

      this.$nextTick(() => {
        this.handleUpdateInfo();
      });
    },
  },

  computed: {
    userRole() {
      return this.$store.state.user.info.role;
    },
  },

  mounted() {
    if (window.innerWidth > 992) {
      this.visible = true;
    }
  },

  methods: {
    handleUpdateInfo: debounce(async function () {
      try {
        const { form, search, noData } = this;
        const {
          directions,
          cultures,
          regions,
          districts,
          managers,
          statuses,
        } = form;

        if (
          !directions.length &&
          !cultures.length &&
          !regions.length &&
          !districts.length &&
          !managers.length &&
          !statuses.length &&
          !search.length &&
          !noData
        ) {
          if (this.searchName.length && !search.length) {
            this.$emit('remove-search');
          }

          this.SET_OBJECTS_LOADING(true);

          Promise.all([this.getObjects(1), this.getPoints()]).then(() => {
            this.SET_OBJECTS_LOADING(false);
            this.SET_FILTER_STATUS(false);
          });
        } else {
          const body = new FormData();

          for (const [key, value] of Object.entries(form)) {
            value.forEach((item) => {
              body.append(`${key}[]`, item);
            });
          }

          body.append('search', search);
          body.append('no_data', Number(noData));

          this.SET_OBJECTS_LOADING(true);
          // const res = await apiRequest('/objects/filters', 'POST', body, true);
          Promise.all([
            apiRequest('/objects/filters', 'POST', body, true),
            apiRequest('/points/filters', 'POST', body, true),
          ]).then(([objects, points]) => {
            console.log(objects, 'objects');
            console.log(points, 'points');
            this.SET_OBJECTS_LOADING(false);

            if (objects.objects) {
              this.SET_OBJECTS(objects.objects.map(parseObject));
              this.SET_POINTS(points);
              this.SET_FILTER_STATUS(true);
            }

            if (this.searchName.length && !search.length) {
              this.$emit('remove-search');
            }
          });
        }
      } catch (error) {
        console.log('handleUpdateInfo:', error);
      }
    }, 1000),

    handleSearch() {
      this.handleUpdateInfo();
    },

    ...mapActions({
      getObjects: 'objects/getObjects',
      getPoints: 'objects/getPoints',
    }),

    ...mapMutations({
      SET_OBJECTS: 'objects/SET_OBJECTS',
      SET_POINTS: 'objects/SET_POINTS',
      SET_FILTER_STATUS: 'filters/SET_FILTER_STATUS',
      SET_OBJECTS_LOADING: 'objects/SET_OBJECTS_LOADING',
    }),
  },
};
</script>

<style lang="scss">
.filters-row {
  @media (max-width: $sm) {
    margin-bottom: 20px;
  }
}

.filters-col {
  &:not(:last-of-type) {
    @media (max-width: $sm) {
      margin-bottom: 20px;
    }
  }

  &:last-of-type {
    @media (max-width: $sm) {
      margin-top: 0;
    }
  }
}

.filters-toggle-mobile {
  display: none;
  margin-bottom: 20px;

  @media (max-width: $lg) {
    display: inline-block;
  }

  .ant-btn {
    @media (max-width: $sm) {
      display: inline-block;
    }
  }
}

.no-data-switch {
  text-transform: uppercase;

  span {
    margin-left: 15px;
  }
}
</style>
