<template>
  <div class="page-dashboard">
    <container>
      <filters
        :options="filterOptions"
        :searchName="searchPointName"
        @remove-search="onSearchNameRemove"
      ></filters>

      <div class="page-dashboard-content">
        <a-row type="flex" :gutter="15">
          <a-col
            :md="{ span: 12 }"
            :xs="{ span: 24 }"
            class="page-dashboard-map"
          >
            <dashboard-map
              :activeObjectId="activeObjectId"
              :geoJsonSource="points"
              :searchPointName="searchPointName"
              @change="onChangeActiveObject"
              @point-tap="onPointTap"
            ></dashboard-map>
          </a-col>

          <a-col :md="{ span: 12 }" :xs="{ span: 24 }">
            <dashboard-list
              :activeObjectId="activeObjectId"
              @change="onChangeActiveObject"
            ></dashboard-list>
          </a-col>
        </a-row>
      </div>
    </container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Container from '../components/Container.vue';
import Filters from '../components/Filters.vue';
import DashboardMap from '../components/DashboardMap.vue';
import DashboardList from '../components/DashboardList.vue';

export default {
  name: 'Dashboard',

  components: {
    Container,
    Filters,
    DashboardMap,
    DashboardList,
  },

  data() {
    return {
      activeObjectId: null,
      searchPointName: '',
    };
  },

  created() {
    Promise.all([this.getObjects(1), this.getPoints()]);
  },

  computed: {
    filterOptions() {
      return {
        managers: this.managers.map(({ id, name, lastName }) => ({
          id,
          name,
          lastName,
        })),
        directions: this.directions,
        cultures: this.cultures,
        regions: this.regions,
        districts: this.districts,
        statuses: this.statuses.filter((status) => status.id !== 0),
      };
    },

    ...mapState({
      directions: (state) => state.filters.directions,
      cultures: (state) => state.filters.cultures,
      regions: (state) => state.filters.regions,
      districts: (state) => state.filters.districts,
      managers: (state) => state.managers.managers,
      statuses: (state) => state.filters.statuses,
      points: (state) => state.objects.points,
    }),
  },

  methods: {
    onChangeActiveObject(id) {
      this.activeObjectId = id;
    },

    onPointTap(object) {
      this.searchPointName = object.name;
    },

    onSearchNameRemove() {
      this.searchPointName = '';
    },

    ...mapActions({
      getObjects: 'objects/getObjects',
      getPoints: 'objects/getPoints',
    }),
  },
};
</script>

<style lang="scss">
.page-dashboard-content {
  margin-top: 15px;

  @media (max-width: $md) {
    margin-top: 15px;
  }

  @media (max-width: $sm) {
    margin-top: 0;
  }
}

.page-dashboard-map {
  position: relative;

  @media (max-width: $md) {
    margin-bottom: 15px;
  }

  .ant-spin-nested-loading {
    position: sticky;
    top: 0;
  }
}
</style>
