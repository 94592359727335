<template>
  <a-spin :spinning="loading">
    <div ref="dashboardMap" class="dashboard-map">
      <mgl-map
        :accessToken="options.mapboxAccessToken"
        :mapStyle.sync="options.mapStyle"
        :center="options.coordinates"
        :bearing="options.bearing"
        :zoom="options.zoom"
        :minZoom="options.minZoom"
        :maxZoom="options.maxZoom"
        :attributionControl="options.attributionControl"
        :scrollZoom="options.scrollZoom"
        @load="onMapLoad"
      >
      </mgl-map>
    </div>
  </a-spin>
</template>

<script>
import { MglMap } from 'vue-mapbox';
// import MapMarker from './MapMarker.vue';

const mapboxAccessToken =
  'pk.eyJ1IjoicmFraW50c2V2YSIsImEiOiJja2h1ZjlkeXAxYzY2MnhwYno3MHJmbDMwIn0.PGNDCcc3oEcJ6lwI5phAWQ';

export default {
  name: 'DashboardMap',

  components: {
    MglMap,
    // MapMarker
  },

  props: {
    geoJsonSource: {
      type: Object,
      default: () => {},
    },

    activeObjectId: {
      type: [Number, String],
      default: null,
    },

    searchPointName: {
      type: [Number, String],
      default: '',
    },
  },

  data() {
    return {
      loading: true,
      clusterExist: false,

      options: {
        mapboxAccessToken,
        coordinates: ['27.567444', '53.893009'],
        mapStyle: `mapbox://styles/mapbox/${
          localStorage.getItem('map-style') || 'light-v10'
        }`,
        attributionControl: false,
        scrollZoom: true,
        zoom: 4,
        bearing: 10,
        minZoom: 4,
        maxZoom: 16,
      },

      geoJsonLayerActiveA: {
        id: 'clusters-active_a',
        type: 'circle',
        source: 'earthquakes1',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#50d86a',
            100,
            '#50d86a',
            750,
            '#50d86a',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      },

      geoJsonLayerActiveB: {
        id: 'clusters-active_b',
        type: 'circle',
        source: 'earthquakes2',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#5bc0de',
            100,
            '#5bc0de',
            750,
            '#5bc0de',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      },

      geoJsonLayerBase: {
        id: 'clusters-base',
        type: 'circle',
        source: 'earthquakes3',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#979797',
            100,
            '#979797',
            750,
            '#979797',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      },

      geoJsonLayerNewObjects: {
        id: 'clusters-new_object',
        type: 'circle',
        source: 'earthquakes4',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#eeb31b',
            100,
            '#eeb31b',
            750,
            '#eeb31b',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      },

      geoJsonLayerPassive: {
        id: 'clusters-passive_object',
        type: 'circle',
        source: 'earthquakes5',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#d80f02',
            100,
            '#d80f02',
            750,
            '#d80f02',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      },
    };
  },

  watch: {
    geoJsonSource() {
      const {
        geoJsonSource: { active_a, active_b, base, new_object, passive },
        geoJsonLayerActiveA,
        geoJsonLayerActiveB,
        geoJsonLayerBase,
        geoJsonLayerNewObjects,
        geoJsonLayerPassive,
      } = this;

      if (!this.clusterExist) {
        this.drowCluster(
          active_a,
          geoJsonLayerActiveA,
          'earthquakes1',
          'active_a',
          '#50d86a',
          '#56e771'
        );

        this.drowCluster(
          active_b,
          geoJsonLayerActiveB,
          'earthquakes2',
          'active_b',
          '#5bc0de',
          '#63d0f1'
        );

        this.drowCluster(
          base,
          geoJsonLayerBase,
          'earthquakes3',
          'base',
          '#979797',
          '#e5e5e5'
        );

        this.drowCluster(
          new_object,
          geoJsonLayerNewObjects,
          'earthquakes4',
          'new_object',
          '#eeb31b',
          '#ffbf1e'
        );

        this.drowCluster(
          passive,
          geoJsonLayerPassive,
          'earthquakes5',
          'passive_object',
          '#d80f02',
          '#f11202'
        );

        this.clusterExist = true;
      } else {
        this.map.off('click', `clusters-active_a`);
        this.map.off('click', `unclustered-point-active_a`);
        this.map.removeLayer('clusters-active_a');
        this.map.removeLayer('cluster-count-active_a');
        this.map.removeLayer('unclustered-point-active_a');
        this.map.removeSource('earthquakes1');

        this.map.off('click', `clusters-active_b`);
        this.map.off('click', `unclustered-point-active_b`);
        this.map.removeLayer('clusters-active_b');
        this.map.removeLayer('cluster-count-active_b');
        this.map.removeLayer('unclustered-point-active_b');
        this.map.removeSource('earthquakes2');

        this.map.off('click', `clusters-base`);
        this.map.off('click', `unclustered-point-base`);
        this.map.removeLayer('clusters-base');
        this.map.removeLayer('cluster-count-base');
        this.map.removeLayer('unclustered-point-base');
        this.map.removeSource('earthquakes3');

        this.map.off('click', `clusters-new_object`);
        this.map.off('click', `unclustered-point-new_object`);
        this.map.removeLayer('clusters-new_object');
        this.map.removeLayer('cluster-count-new_object');
        this.map.removeLayer('unclustered-point-new_object');
        this.map.removeSource('earthquakes4');

        this.map.off('click', `clusters-passive_object`);
        this.map.off('click', `unclustered-point-passive_object`);
        this.map.removeLayer('clusters-passive_object');
        this.map.removeLayer('cluster-count-passive_object');
        this.map.removeLayer('unclustered-point-passive_object');
        this.map.removeSource('earthquakes5');

        this.$nextTick(() => {
          this.drowCluster(
            active_a,
            geoJsonLayerActiveA,
            'earthquakes1',
            'active_a',
            '#50d86a',
            '#56e771'
          );

          this.drowCluster(
            active_b,
            geoJsonLayerActiveB,
            'earthquakes2',
            'active_b',
            '#5bc0de',
            '#63d0f1'
          );

          this.drowCluster(
            base,
            geoJsonLayerBase,
            'earthquakes3',
            'base',
            '#979797',
            '#e5e5e5'
          );

          this.drowCluster(
            new_object,
            geoJsonLayerNewObjects,
            'earthquakes4',
            'new_object',
            '#eeb31b',
            '#ffbf1e'
          );

          this.drowCluster(
            passive,
            geoJsonLayerPassive,
            'earthquakes5',
            'passive_object',
            '#d80f02',
            '#f11202'
          );
        });
      }
    },

    activeObjectId(val) {
      const { mapActions } = this;

      if (val !== null) {
        let coordinates = null;

        for (const [, value] of Object.entries(this.geoJsonSource)) {
          const { features } = value;

          const object = features.find(
            (feature) => feature.properties.id === val
          );

          if (object) {
            coordinates = object.geometry.coordinates;
          }
        }

        if (mapActions) {
          this.flyTo(coordinates, 14, 1.3);
        }
      } else {
        if (mapActions) {
          this.flyTo(this.options.coordinates, this.options.zoom + 1, 1.3);
        }
      }
    },

    searchPointName(newVal, prevVal) {
      const { mapActions } = this;

      if (!newVal.length && prevVal.length && mapActions) {
        this.flyTo(this.options.coordinates, this.options.zoom + 1, 1.3);
      }
    },
  },

  created() {
    this.map = null;
    this.mapActions = null;
  },

  methods: {
    onMapLoad(e) {
      this.loading = false;
      this.map = e.map;
      this.mapActions = e.component.actions;

      this.map.getStyle().layers.forEach((thisLayer) => {
        if (thisLayer.id.indexOf('-label') > 0) {
          this.map.setLayoutProperty(thisLayer.id, 'text-field', [
            'get',
            'name_ru',
          ]);
        }
      });

      this.flyTo(this.options.center);

      if (Object.keys(this.geoJsonSource).length) {
        const {
          geoJsonSource: { active_a, active_b, base, new_object, passive },
          geoJsonLayerActiveA,
          geoJsonLayerActiveB,
          geoJsonLayerBase,
          geoJsonLayerNewObjects,
          geoJsonLayerPassive,
        } = this;

        this.drowCluster(
          active_a,
          geoJsonLayerActiveA,
          'earthquakes1',
          'active_a',
          '#50d86a',
          '#56e771'
        );

        this.drowCluster(
          active_b,
          geoJsonLayerActiveB,
          'earthquakes2',
          'active_b',
          '#5bc0de',
          '#63d0f1'
        );

        this.drowCluster(
          base,
          geoJsonLayerBase,
          'earthquakes3',
          'base',
          '#979797',
          '#e5e5e5'
        );

        this.drowCluster(
          new_object,
          geoJsonLayerNewObjects,
          'earthquakes4',
          'new_object',
          '#eeb31b',
          '#ffbf1e'
        );

        this.drowCluster(
          passive,
          geoJsonLayerPassive,
          'earthquakes5',
          'passive_object',
          '#d80f02',
          '#f11202'
        );

        this.clusterExist = true;
      }
    },

    drowCluster(data, layer, source, id, bgColor, borderColor) {
      this.map.addSource(source, {
        type: 'geojson',
        data,
        cluster: true,
        clusterMaxZoom: this.options.maxZoom,
        clusterRadius: 50,
      });

      this.map.addLayer(layer);
      this.map.addLayer({
        id: `cluster-count-${id}`,
        type: 'symbol',
        source,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 13,
        },
        paint: {
          'text-color': id === 'base' ? '#ffffff' : '#000000',
        },
      });

      this.map.addLayer({
        id: `unclustered-point-${id}`,
        type: 'circle',
        source,
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': bgColor,
          'circle-radius': 10,
          'circle-stroke-width': 3,
          'circle-stroke-color': borderColor,
        },
      });

      this.map.on('click', `clusters-${id}`, (e) => {
        const features = this.map.queryRenderedFeatures(e.point, {
          layers: [`clusters-${id}`],
        });
        const clusterId = features[0].properties.cluster_id;

        console.log(features, 'features');
        console.log(clusterId, 'clusterId');

        this.map
          .getSource(source)
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            const coordinates = features[0].geometry.coordinates;
            this.flyTo(coordinates, zoom, 1.3);
          });
      });

      this.map.on('click', `unclustered-point-${id}`, (e) => {
        const coordinates = e.features[0].geometry.coordinates;
        this.flyTo(coordinates, 14, 1.3);

        this.$emit('point-tap', e.features[0].properties);
      });
    },

    async flyTo(center, zoom = 5, speed = '0.4', bearing = 0) {
      const actions = this.mapActions;

      if (actions) {
        await actions.flyTo({
          center,
          zoom,
          speed,
          bearing,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.dashboard-map {
  width: 100%;
  height: 100vh;

  @media (max-width: $md) {
    height: 400px;
  }
}

.mapboxgl-canvas {
  outline: none !important;
}
</style>
