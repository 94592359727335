<template>
  <div class="page-profile">
    <container>
      <a-row :gutter="20">
        <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
          <a-form-model
            ref="profileForm"
            class="page-profile-form"
            :model="form"
            @submit.prevent="onSubmit"
          >
            <a-form-model-item
              ref="avatar"
              label="Фото"
              prop="avatar"
              class="mb-0"
            >
              <a-upload
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :before-upload="onUpdateFile"
              >
                <img v-if="preview" :src="preview" alt="Фото" />
                <div v-else>
                  <a-icon type="plus"></a-icon>
                  <div class="ant-upload-text">Загрузить</div>
                </div>
              </a-upload>
            </a-form-model-item>

            <a-form-model-item ref="name" label="Имя" prop="name">
              <a-input
                size="large"
                placeholder="Иван"
                v-model="form.firstName"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item ref="lastName" label="Фамилия" prop="lastName">
              <a-input
                size="large"
                placeholder="Иванович"
                v-model="form.lastName"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item ref="phone" label="Номер телефона" prop="phone">
              <a-input
                type="tel"
                size="large"
                placeholder="+375291112233"
                v-model="form.phone"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item
              ref="password"
              label="Новый пароль"
              prop="password"
              has-feedback
              help="Минимум 6 символов"
            >
              <a-input-password
                v-model="form.password"
                size="large"
                placeholder="Введите ваш новый пароль"
              ></a-input-password>
            </a-form-model-item>

            <a-form-model-item
              v-if="form.password.length >= 6"
              ref="passwordReapeat"
              label="Повторите введенный пароль"
            >
              <a-input-password
                v-model="passwordRepeat"
                size="large"
                placeholder="Введите ваш новый пароль еще раз"
              ></a-input-password>
            </a-form-model-item>

            <a-form-model-item>
              <a-button
                type="primary"
                htmlType="submit"
                class="mr-10"
                :loading="loading"
              >
                Сохранить
              </a-button>
              <a-button @click="$router.back()"> Назад </a-button>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>

      <a-row :gutter="20">
        <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
          <a-form-model ref="appForm" class="page-app-form">
            <a-form-model-item label="Стиль карты:" prop="mapStyle">
              <a-select
                v-model="mapStyle"
                size="large"
                placeholder="Выберите стиль карты"
                @change="handleUpdateMapStyle"
              >
                <a-select-option
                  v-for="(style, index) in styles"
                  :key="index"
                  :value="style.value"
                >
                  {{ style.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>
    </container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import apiRequest from '../js/helpers/apiRequest.js';

import Container from '../components/Container.vue';

export default {
  name: 'Profile',

  components: {
    Container,
  },

  data() {
    return {
      loading: false,
      preview: '',
      passwordRepeat: '',
      mapStyle: localStorage.getItem('map-style')
        ? localStorage.getItem('map-style')
        : undefined,
      styles: [
        { name: 'Улицы', value: 'streets-v11' },
        { name: 'Светлая', value: 'light-v10' },
        { name: 'Темная', value: 'dark-v10' },
        { name: 'Спутник', value: 'satellite-v9' },
      ],
      form: {
        avatar: null,
        name: '',
        lastName: '',
        phone: '',
        password: '',
      },
      // appSettings: {}
    };
  },

  watch: {
    user(info) {
      const { name, lastName, phone, avatar } = info;

      this.preview = avatar;
      this.form.firstName = name;
      this.form.lastName = lastName;
      this.form.phone = phone;
    },
  },

  computed: {
    user() {
      return this.$store.state.user.info;
    },
  },

  created() {
    this.$nextTick(() => {
      const { firstName, lastName, phone, avatar } = this.user;

      this.preview = avatar;
      this.form.firstName = firstName;
      this.form.lastName = lastName;
      this.form.phone = phone;
    });
  },

  methods: {
    onUpdateFile(file) {
      this.getBase64(file, (preview) => {
        this.preview = preview;
        this.form.avatar = file;
      });
    },

    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },

    handleUpdateMapStyle(val) {
      localStorage.setItem('map-style', val);

      this.$notification.success({
        message: 'МинералАгроСоюз',
        description: 'Стиль карты изменен',
      });
    },

    async onSubmit() {
      try {
        const {
          form: { avatar, firstName, lastName, phone, password },
          passwordRepeat,
        } = this;
        const body = new FormData();

        body.append('first_name', firstName);
        body.append('last_name', lastName);
        body.append('phones', phone);

        if (password.length && password !== passwordRepeat) {
          this.$notification.warning({
            message: 'МинералАгроСоюз',
            description:
              'Пароль должен состоять минимум из 6 символов и совпадать с проверкой пароля',
          });
          return;
        } else {
          body.append('password', password);
        }

        if (avatar) {
          body.append('photo', avatar);
        }

        this.loading = true;
        const res = await apiRequest('/profile/edit', 'POST', body, true);

        if (res.user) {
          Promise.all([this.getUserInfo(), this.getManagers()]).then(() => {
            this.$notification.success({
              message: 'МинералАгроСоюз',
              description: 'Ваши данные успешно обновлены!',
            });
            this.form.password = '';
            this.passwordRepeat = '';
            this.loading = false;
          });
        }
      } catch (error) {
        console.log('onSubmit:', error);
      }
    },

    ...mapActions({
      getUserInfo: 'user/getUserInfo',
      getManagers: 'managers/getManagers',
    }),
  },
};
</script>

<style lang="scss"></style>
