<template>
  <div class="dashboard-list">
    <div v-if="filterActive" class="dashboard-list-filter-find">
      {{ `Найдено объектов: ${objects.length}` }}
    </div>
    <a-spin :spinning="loading">
      <a-collapse accordion :bordered="false" @change="onChangeObject">
        <a-collapse-panel
          v-for="item in objects"
          :key="item.id"
          :id="`collapse-panel-${item.id}`"
          :showArrow="false"
          class="dashboard-list-item"
        >
          <template slot="extra">
            <div class="dashboard-list-item-header">
              <a-avatar
                :size="90"
                class="dashboard-list-item-object-id object-id"
              >
                {{ item.id }}
              </a-avatar>
              <div class="dashboard-list-item-header-info">
                <div
                  v-if="item.name"
                  class="dashboard-list-item-header-info-name"
                >
                  {{ item.name }}

                  <a-tooltip
                    v-if="item.noData && item.noData.length"
                    class="ml-10"
                    overlay-class-name="dashboard-list-item-header-info-no-data"
                    placement="top"
                  >
                    <template slot="title">
                      Нет информации:

                      <ul>
                        <li v-for="(item, index) in item.noData" :key="index">
                          {{ `- ${item}` }}
                        </li>
                      </ul>
                    </template>

                    <a-icon type="info-circle"></a-icon>
                  </a-tooltip>
                </div>
                <b
                  v-if="item.unp"
                  class="dashboard-list-item-header-info-comment"
                >
                  {{ `УНП: ${item.unp}` }}
                </b>
                <div
                  v-if="item.comment"
                  class="dashboard-list-item-header-info-comment mt-0-i"
                >
                  <b>Комментарий:</b>
                  {{ item.comment }}
                </div>
                <br />
                <b
                  v-if="item.swonareaText"
                  class="dashboard-list-item-header-info-comment"
                >
                  {{ `Посевные площади хозяйства (Га): ${item.swonareaText}` }}
                </b>
                <div
                  v-if="item.id !== activeObjectId"
                  class="dashboard-list-item-header-info-status"
                >
                  <span
                    class="dashboard-list-item-header-info-status-indicator"
                    :style="`background-color: ${getStatus(item.status).color}`"
                  ></span>
                  <span class="dashboard-list-item-header-info-status-title">
                    {{ getStatus(item.status).name }}
                    <!-- Новый -->
                  </span>
                  <span class="dashboard-list-item-header-info-status-date">
                    {{ format(new Date(item.updatedAt), 'dd.MM.yyyy') }}
                  </span>
                </div>
              </div>
            </div>
          </template>

          <div class="dashboard-list-item-content">
            <a-row>
              <a-col v-if="regions.length" :span="12">
                <div class="dashboard-list-item-title mt-0-i">Область:</div>
                <div class="dashboard-list-item-address">
                  {{ regions.find((region) => region.id == item.region).name }}
                </div>
              </a-col>

              <a-col :span="12">
                <div class="dashboard-list-item-title mt-0-i">Район:</div>
                <div class="dashboard-list-item-address">
                  {{ item.district }}
                </div>
              </a-col>
            </a-row>

            <div class="dashboard-list-item-title">Адрес:</div>
            <div class="dashboard-list-item-address">
              {{ item.address }}
            </div>

            <template v-if="item.managers.length">
              <div class="dashboard-list-item-title">Менеджеры объекта:</div>
              <ul>
                <li v-for="manager in item.managers" :key="manager.id">
                  <user
                    :avatar="manager.avatar"
                    :title="`${manager.name} ${manager.lastName}`"
                    light
                  ></user>
                </li>
              </ul>
            </template>

            <template v-if="item.directions.length">
              <div class="dashboard-list-item-title">Направления работы:</div>
              <ul>
                <li v-for="direction in item.directions" :key="direction.id">
                  <a-avatar
                    :size="35"
                    icon="check"
                    style="backgroundcolor: #42af63"
                  >
                  </a-avatar>
                  <span class="ml-10">{{ direction.name }}</span>
                </li>
              </ul>
            </template>

            <div class="dashboard-list-item-title">Текущий статус объекта:</div>
            <div class="dashboard-list-item-header-info-status">
              <span
                class="dashboard-list-item-header-info-status-indicator"
                :style="`background-color: ${getStatus(item.status).color}`"
              ></span>
              <span class="dashboard-list-item-header-info-status-title">
                {{ getStatus(item.status).name }}
              </span>
              <span class="dashboard-list-item-header-info-status-date">
                {{ format(new Date(item.updatedAt), 'dd.MM.yyyy') }}
              </span>
            </div>
            <!-- <div class="text-size-10 mt-10">
            Отгружено 100 тыс тонн семян, поставлено 500 мешков
          </div> -->

            <div class="mt-10">
              <router-link :to="`/object/${item.id}`">
                <a-button class="mr-15 mt-15" ghost>
                  Подробнее об объекте
                </a-button>
              </router-link>
              <router-link
                :to="{
                  path: `/object/${item.id}`,
                  query: { status: true },
                }"
              >
                <a-button class="mt-15" ghost>Изменить статус</a-button>
              </router-link>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </a-spin>

    <div
      v-if="objects.length && !filterActive"
      class="dashboard-list-pagination"
    >
      <a-pagination
        v-model="page"
        simple
        hideOnSinglePage
        :total="totalObjects"
        :defaultPageSize="perPage"
      ></a-pagination>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { format } from 'date-fns';
import { debounce } from 'lodash';

import User from './User.vue';

export default {
  name: 'DashboardList',

  components: {
    User,
  },

  props: {
    activeObjectId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      page: 1,
    };
  },

  watch: {
    page(page) {
      this.loadNewPage(page, this);
    },

    currentPage(page) {
      this.page = page;
    },
  },

  computed: {
    format: () => format,

    ...mapState({
      objects: (state) => state.objects.objects,
      currentPage: (state) => state.objects.currentPage,
      totalObjects: (state) => state.objects.totalObjects,
      perPage: (state) => state.objects.perPage,
      loading: (state) => state.objects.loading,
      filterActive: (state) => state.filters.active,
      regions: (state) => state.filters.regions,
    }),
  },

  methods: {
    onChangeObject(item) {
      if (item !== undefined) {
        this.$emit('change', item);
      } else {
        this.$emit('change', null);
      }
    },

    getStatus(id) {
      if (id == 0) {
        return { name: 'Базовый', color: '#979797' };
      } else if (id == 1) {
        return { name: 'Активный А', color: '#50d86a' };
      } else if (id == 2) {
        return { name: 'Активный Б', color: '#5bc0de' };
      } else if (id == 3) {
        return { name: 'Пассивный', color: '#d80f02' };
      } else if (id == 4) {
        return { name: 'Новый', color: '#eeb31b' };
      } else if (id == 5) {
        return { name: 'Операционка', color: '#5bdebb' };
      }
    },

    loadNewPage: debounce(async (page, self) => {
      self.$store.commit('objects/SET_OBJECTS_LOADING', true);
      await self.getObjects(page);
      self.$store.commit('objects/SET_OBJECTS_LOADING', false);

      self.$scrollTo('.dashboard-list', 1000);
    }, 200),

    ...mapActions({
      getObjects: 'objects/getObjects',
    }),
  },
};
</script>

<style lang="scss">
.dashboard-list {
  padding-bottom: 25px;

  .ant-collapse {
    border-radius: 0;
    background-color: $gray-100;

    & > .ant-collapse-item {
      &.ant-collapse-item-active {
        background-color: $black-100;

        .ant-collapse-header {
          color: $white;
          transition: none;
        }
      }

      & > .ant-collapse-header {
        padding: 30px 30px 30px 80px;

        @media (max-width: $xl) {
          padding: 30px 30px 30px 30px;
        }

        @media (max-width: $lg) {
          padding: 15px 15px 15px 15px;
        }

        .ant-collapse-extra {
          float: none;
        }
      }
    }
  }

  .ant-collapse-content-box {
    padding: 15px 30px 30px 210px;

    @media (max-width: $xl) {
      padding: 15px 30px 30px 150px;
    }

    @media (max-width: $lg) {
      padding: 15px 15px 15px 90px;
    }
  }

  .ant-collapse-content {
    color: $white;
  }
}

.dashboard-list-item-header {
  display: flex;
  align-items: center;
}

.dashboard-list-item-object-id {
  flex-shrink: 0;
}

.dashboard-list-item-header-info {
  margin-left: 40px;

  @media (max-width: $xl) {
    margin-left: 30px;
  }

  @media (max-width: $lg) {
    margin-left: 15px;
  }
}

.dashboard-list-item-header-info-name {
  text-transform: uppercase;
  font-weight: 800;
}

.dashboard-list-item-header-info-comment {
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.5;
  max-width: 350px;
}

.dashboard-list-item-header-info-status {
  margin-top: 10px;
  display: flex;
  align-items: center;
  line-height: 1;
}

.dashboard-list-item-header-info-status-indicator {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border-radius: 50%;
}

.dashboard-list-item-header-info-status-title {
  margin-right: 10px;
}

.dashboard-list-item-header-info-status-date {
  font-size: 10px;
  color: $gray-300;
}

.dashboard-list-item-content {
  ul {
    margin: 10px 0 0;
    padding: 0;
    list-style: none;
  }

  li {
    + li {
      margin-top: 10px;
    }
  }
}

.dashboard-list-item-address {
}

.dashboard-list-item-title {
  margin-top: 30px;
  margin-bottom: 5px;
  font-weight: 700;
}

.dashboard-list-pagination {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.dashboard-list-filter-find {
  font-size: 18px;
  color: #979797;
  margin-bottom: 15px;
  line-height: 1;
}

.dashboard-list-item-header-info-no-data {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
</style>
