<template>
  <div class="page-edit">
    <container>
      <a-row :gutter="[0, 20]">
        <a-col :span="24">
          <router-link to="/" class="back-to-home">
            <a-icon type="home" class="mr-5"></a-icon>

            <span>Вернуться к списку объектов</span>
          </router-link>
        </a-col>

        <a-col :span="24">
          <a-button class="mb-15" @click="createUser">
            Добавить пользователя
          </a-button>

          <div class="page-edit-user-table">
            <a-table
              bordered
              :loading="usersLoading"
              :columns="columns"
              :data-source="users"
              :pagination="pagination"
            >
              <div
                slot="avatar"
                slot-scope="text, record"
                style="text-align: center"
              >
                <a-avatar
                  :size="40"
                  :src="record.avatar"
                  icon="user"
                ></a-avatar>
              </div>

              <a
                slot="email"
                slot-scope="text, record"
                :href="`mailto:${record.email}`"
              >
                {{ record.email }}
              </a>

              <div slot="phone" slot-scope="text, record">
                <a
                  v-for="(phone, index) in record.phone.split(',')"
                  :key="index"
                  :href="`mailto:+${phone.replace(/ /g, '')}`"
                >
                  {{ `+${phone.replace(/ /g, '')}` }}
                </a>
              </div>

              <div
                slot="operation"
                slot-scope="text, record"
                class="page-edit-operation-cell"
              >
                <a-button
                  type="link"
                  icon="form"
                  @click="editUser(record.key)"
                ></a-button>

                <a-popconfirm
                  :title="`Вы уверены что хотите удалить ${record.fullName}?`"
                  ok-text="Да"
                  cancel-text="Нет"
                  @confirm="deleteUser(record.key)"
                >
                  <a-button type="link" icon="delete"></a-button>
                </a-popconfirm>
              </div>
            </a-table>
          </div>
        </a-col>
      </a-row>
    </container>

    <a-modal
      :title="modalTitle"
      :visible="editModalVisible"
      :confirm-loading="editConfirmLoading"
      ok-text="Сохранить"
      @ok="saveChanges"
      @cancel="onCloseEditModal"
    >
      <div class="ant-modal-body-content">
        <a-form-model>
          <a-form-model-item ref="name" label="Имя *" prop="name">
            <a-input
              size="large"
              placeholder="Иван"
              v-model="userData.firstName"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item ref="lastName" label="Фамилия *" prop="lastName">
            <a-input
              size="large"
              placeholder="Иванович"
              v-model="userData.lastName"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item ref="phone" label="Email *" prop="email">
            <a-input
              type="tel"
              size="large"
              placeholder="email@mail.com"
              v-model="userData.email"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item ref="phone" label="Номер телефона *" prop="phone">
            <a-input
              type="tel"
              size="large"
              placeholder="+375291112233"
              v-model="userData.phone"
            ></a-input>
          </a-form-model-item>

          <a-form-model-item ref="regions" label="Области" prop="regions">
            <a-select
              v-model="userData.regions"
              mode="multiple"
              size="large"
              placeholder="Области"
            >
              <a-select-option
                v-for="option in regioins"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item ref="regions" label="Менеджеры" prop="regions">
            <a-select
              v-model="userData.managers"
              mode="multiple"
              size="large"
              placeholder="Менеджеры"
            >
              <a-select-option
                v-for="option in managers"
                :key="option.id"
                :value="option.id"
              >
                {{ option.full }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item
            ref="password"
            label="Пароль"
            prop="password"
            has-feedback
            help="Минимум 6 символов"
          >
            <a-input-password
              v-model="userData.password"
              size="large"
              placeholder="Введите пароль"
            ></a-input-password>
          </a-form-model-item>

          <a-form-model-item
            v-if="userData.password.length >= 6"
            ref="passwordReapeat"
            label="Повторите введенный пароль"
          >
            <a-input-password
              v-model="userData.passwordRepeat"
              size="large"
              placeholder="Введите пароль еще раз"
            ></a-input-password>
          </a-form-model-item>

          <a-form-model-item ref="role" label="Роль *" prop="role">
            <a-radio-group
              v-model="userData.role"
              :options="roles"
            ></a-radio-group>
          </a-form-model-item>

          <a-form-model-item ref="status" label="Активен" prop="status">
            <a-switch v-model="userData.status"></a-switch>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { BASE_PATH } from '../js/const/index';
import Container from '../components/Container';
import apiRequest from '../js/helpers/apiRequest';

const columns = [
  {
    title: 'Фото',
    dataIndex: 'avatar',
    key: 'avatar',
    scopedSlots: { customRender: 'avatar' },
  },
  {
    title: 'Имя / Фамилия',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    scopedSlots: { customRender: 'email' },
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone',
    key: 'phone',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: 'Роль',
    dataIndex: 'roleInfo',
    key: 'roleInfo',
  },
  {
    title: 'Статус',
    dataIndex: 'statusInfo',
    key: 'statusInfo',
  },
  {
    title: '',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];

export default {
  name: 'EditUsers',

  components: {
    Container,
  },

  data() {
    return {
      BASE_PATH,
      columns,
      users: [],
      pagination: false,
      usersLoading: false,
      isEditUser: false,
      isCreateUser: false,
      editConfirmLoading: false,
      roles: [
        { label: 'Администратор', value: 'admin' },
        { label: 'Менеджер', value: 'manager' },
      ],
      userData: {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        regions: [],
        managers: [],
        password: '',
        passwordRepeat: '',
        role: null,
        status: false,
      },
    };
  },

  computed: {
    editModalVisible() {
      const { isEditUser, isCreateUser } = this;

      return isEditUser || isCreateUser;
    },

    modalTitle() {
      const { isEditUser, isCreateUser } = this;

      return isEditUser
        ? 'Редактирование пользователя'
        : isCreateUser
        ? 'Создание пользователя'
        : '';
    },

    regioins() {
      return this.$store.state.filters.regions;
    },

    managers() {
      return this.$store.state.managers.managers;
    },
  },

  async created() {
    this.usersLoading = true;
    await this.getUsers();
    this.usersLoading = false;
  },

  methods: {
    onCloseEditModal() {
      this.isEditUser = false;
      this.isCreateUser = false;

      this.userData = {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        regions: [],
        managers: [],
        password: '',
        passwordRepeat: '',
        role: null,
        status: false,
      };
    },

    editUser(key) {
      const user = this.users.find((user) => user.key === key);

      this.userData.id = key;
      this.userData.firstName = user.firstName;
      this.userData.lastName = user.lastName;
      this.userData.email = user.email;
      this.userData.phone = user.phone;
      this.userData.regions = user.premissions.regions;
      this.userData.managers = user.premissions.managers;
      this.userData.role = user.role;
      this.userData.status = user.status;
      this.isEditUser = true;
    },

    createUser() {
      this.isCreateUser = true;
    },

    checkForm() {
      let valid = true;

      const {
        userData: {
          firstName,
          lastName,
          email,
          phone,
          password,
          passwordRepeat,
          role,
        },
        isCreateUser,
      } = this;

      if (!firstName) {
        valid = false;
      }

      if (!lastName) {
        valid = false;
      }

      if (!email) {
        valid = false;
      }

      if (!phone) {
        valid = false;
      }

      if (isCreateUser) {
        if (!password || password.length < 6 || password !== passwordRepeat) {
          valid = false;
        }
      }

      if (password && (password.length < 6 || password !== passwordRepeat)) {
        valid = false;
      }

      if (!role) {
        valid = false;
      }

      return valid;
    },

    async deleteUser(key) {
      try {
        const body = new FormData();

        body.append('id', key);

        const res = await apiRequest('/admin/user/delete', 'POST', body, true);

        if (res.success) {
          this.users = this.users.filter((user) => user.key !== key);
        }
      } catch (error) {
        console.log('deleteUser', error);
      }
    },

    async saveChanges() {
      const valid = this.checkForm();

      if (!valid) {
        this.$notification.warning({
          message: 'МинералАгроСоюз',
          description: 'Заполните все обязательные поля',
        });
      } else {
        const {
          isEditUser,
          isCreateUser,
          userData: {
            id,
            firstName,
            lastName,
            email,
            phone,
            regions,
            managers,
            password,
            role,
            status,
          },
        } = this;
        try {
          const body = new FormData();

          body.append('name', email);
          body.append('first_name', firstName);
          body.append('last_name', lastName);
          body.append('email', email);
          body.append('phones', phone);
          regions.forEach((region) => {
            body.append('regions[]', region);
          });
          managers.forEach((manager) => {
            body.append('managers[]', manager);
          });
          body.append('role', role);
          body.append('status', Number(status));

          if (password) {
            body.append('password', password);
          }

          this.editConfirmLoading = true;

          if (isEditUser) {
            body.append('id', id);
            const res = await apiRequest(
              '/admin/user/update',
              'POST',
              body,
              true
            );

            if (res.user) {
              await this.getUsers();
            }
          }

          if (isCreateUser) {
            const res = await apiRequest(
              '/admin/user/create',
              'POST',
              body,
              true
            );

            if (res.user) {
              await this.getUsers();
            }
          }

          this.editConfirmLoading = false;
          this.onCloseEditModal();
        } catch (error) {
          console.log('saveChanges', error);
        }
      }
    },

    async getUsers() {
      try {
        const res = await apiRequest('/admin/users', 'GET', null, true);

        if (res.users) {
          this.users = res.users.map(
            ({
              id,
              profile_photo_url,
              first_name,
              last_name,
              email,
              phones,
              role,
              status,
              premissions,
            }) => ({
              key: id,
              avatar: profile_photo_url,
              firstName: first_name,
              lastName: last_name,
              fullName: `${first_name} ${last_name}`,
              email,
              phone: phones,
              role,
              roleInfo: role === 'admin' ? 'Администратор' : 'Менеджер',
              status: !!Number(status),
              statusInfo: Number(status) ? 'Активен' : 'Не активен',
              premissions: premissions ? premissions : { regions: [] },
            })
          );
        }
      } catch (error) {
        console.log('getUsers', error);
      }
    },
  },
};
</script>

<style lang="scss">
.page-edit {
  padding-bottom: 25px;
}

.page-edit-operation-cell {
  display: flex;
  justify-content: center;

  .ant-btn {
    + .ant-btn {
      margin-left: 10px;
    }
  }
}
</style>
